import { Global } from "@emotion/react";
import React, { useEffect } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";
// https://github.com/remix-run/react-router/tree/main/packages/react-router-dom-v5-compat
import loadable from "@loadable/component";

import {
  ComponoLogoMark,
  LayoutBody,
  LayoutContent,
  LayoutHeader,
  LayoutMobileHeader,
  LayoutScreen,
  LayoutSidebar,
  MobileHeader,
} from "@compono/ui";

import * as zendesk from "@shortlyster/zendesk-kit";

import { PublicJoin as EngagePublicJoin } from "../engage-ui/PublicJoin";

import AnalyticsIdentifier from "~/components/AnalyticsIdentifier";
import { AuthorizationProvider } from "~/components/auth";
import { Providers } from "~/components/Providers";
import { Sentry } from "~/components/Sentry";
import { TopNav } from "~/components/TopNav";
import { SideNav } from "~/components/SideNav";
import { useAuthentication } from "~/hooks/useAuthentication";
import { useAuthorization } from "~/hooks/useAuthorization";
import { env } from "~/env";
import { LaunchDarklyIdentifier } from "./components/LaunchDarkly";
import CultureReport from "../engage-ui/src/features/shared/CultureReport";

const EmployerApp = loadable(
  () => import(/* webpackPrefetch: true */ "../employer-ui/EmployerApp")
);
const EngageApp = loadable(
  () => import(/* webpackPrefetch: true */ "../engage-ui/EngageApp")
);
const ShortlysterApp = loadable(
  () => import(/* webpackPrefetch: true */ "../shortlyster-ui/ShortlysterApp")
);
const TrackyrApp = loadable(
  () => import(/* webpackPrefetch: true */ "../trackyr-ui/TrackyrApp")
);
const DevelopApp = loadable(
  () => import(/* webpackPrefetch: true */ "./features/develop/DevelopApp")
);

function addPathPrefix(paths: string[]) {
  return paths.map((p) => `/organisation/:orgId/${p}`);
}

function PrivateRouter() {
  const { permissions } = useAuthorization();

  return (
    <Switch>
      <Route exact path={["/shared/cultureReport"]}>
        <CultureReport />
      </Route>
      {permissions.product?.hire && (
        <Route exact path={addPathPrefix(["", "listing/create"])}>
          <ShortlysterApp />
        </Route>
      )}

      {permissions.product?.hire && (
        <Route
          exact
          path={addPathPrefix([
            "interviews",
            "messages",
            "listing/:listingId/applicants/:matchId?",
            "listing/:listingId/external-applicants/:applicationId",
            "listing/:listingId",
            "applications/:applicationId",
            "interviews/create/:applicationId",
            "interviews/:interviewId",
            "interviews/:interviewId/edit",
            "interviews/:interviewId/cancel",
            "messages/:listingId?",
          ])}
        >
          {/* reset react-router routeMatch */}
          <Route path={"/organisation/:orgId"}>
            <TrackyrApp />
          </Route>
        </Route>
      )}

      {permissions.hireReports.view && (
        <Route exact path={addPathPrefix(["reports"])}>
          <Route path={"/organisation/:orgId"}>
            <TrackyrApp />
          </Route>
        </Route>
      )}

      {permissions.product?.hire && (
        <Route
          path={addPathPrefix([
            "applications/:applicationId",
            "listing/:listingId/applicants/:applicantId?",
          ])}
        >
          {/* reset react-router routeMatch */}
          <Route path={"/organisation/:orgId"}>
            <TrackyrApp />
          </Route>
        </Route>
      )}

      {permissions.product?.engage && (
        <Route exact path="/organisation/:orgId">
          <EngageApp />
        </Route>
      )}

      {permissions.product?.engage && (
        <Route
          path={[
            ...addPathPrefix([
              "engage",
              "dashboard",
              "editCampaign/:campaignSk",
              "insights",
              "culture",
              "workpersonality",
              "campaign/:campaignSk",
              "campaigns",
              "campaigns/campaignRespondents/:campaignId",
              "timezone",
              "personality-assessment",
              "sharing",
            ]),
          ]}
        >
          <Route path="/organisation/:orgId">
            {/* ^^reset useRouteMatch */}
            <EngageApp />
          </Route>
        </Route>
      )}

      {permissions.product?.develop && (
        <Route exact path={addPathPrefix(["courses", "courses-iframe"])}>
          <Route path="/organisation/:orgId">
            <DevelopApp />
          </Route>
        </Route>
      )}

      <Route
        exact
        path={addPathPrefix([
          "cronofy/success",
          "join",
          "employer/join",
          "account",
          "settings/:section",
          "settings",
          "joined",
          "setup",
          "teams",
          "manage-tags",
          "teams/create",
          "teams/:teamId",
          "teams/:teamId/add-members",
          "teams/:teamId/jobs",
          "teams/:teamId/settings",
          "job-board-integration",
          "job-board-integration/new-account",
          "job-board-integration/new-account-success",
          "employer-api",
          "integrations",
          "profile",
          "calendar",
          "users/role-permissions",
          "users",
          "users/:userState",
          "groups",
          "groups/:groupId",
          "groups/:groupId/manage",
          "message-templates",
        ])}
      >
        <EmployerApp />
      </Route>

      {permissions.product?.hire && (
        <Route path="*">
          <ShortlysterApp />
        </Route>
      )}
    </Switch>
  );
}

/*
  LogIn is rendered by Public when no other routes match.
  It calls Auth0's loginWithRedirect function on mount
*/
const LogIn = () => {
  const { loginWithRedirect } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    loginWithRedirect({
      appState: { returnTo: `${location?.pathname}${location?.search}` },
    });
  }, []);

  return null;
};

/*
  Public is rendered by Gatekeeper for unauthenticated (anonymous) users.
*/
const Public = () => (
  <Switch>
    <Route exact path={["/join", "/employer/join"]}>
      <EmployerApp />
    </Route>
    <Route exact path={addPathPrefix(["dashboard", "dashboard/surveys"])}>
      <EngagePublicJoin />
    </Route>
    <Route exact path={["/shared/cultureReport"]}>
      <CultureReport />
    </Route>
    <Route path="*">
      {/* if location does not match any public routes then redirect to login */}
      <LogIn />
    </Route>
  </Switch>
);

/*
  Private is rendered by Gatekeeper for authenticated users.
*/
const Private = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuthentication();
  const templatesMatch = useRouteMatch([
    `/organisation/:orgId/templates`,
    `/shared/cultureReport`,
  ]);

  /* init Zendesk */
  useEffect(() => {
    if (env.APP_RUN_ZENDESK) {
      getAccessTokenSilently().then((token) => {
        if (token) {
          window.addEventListener("load", zendesk.reauthenticate);
          zendesk.init(env.ZENDESK_TOKEN, {}, env.ZENDESK_TOKENURL, token);
        }
      });
    }
  }, [isAuthenticated]);

  return (
    <AnalyticsIdentifier>
      <LaunchDarklyIdentifier>
        <Global
          styles={{
            ":target": { scrollMarginTop: "70px" },
            body: { fontFamily: "DM Sans, sans-serif" },
          }}
        />
        <LayoutScreen>
          <LayoutMobileHeader>
            <MobileHeader hasSidebar={!templatesMatch}>
              <ComponoLogoMark />
            </MobileHeader>
          </LayoutMobileHeader>
          <LayoutSidebar>{!templatesMatch && <SideNav />}</LayoutSidebar>
          <LayoutBody>
            {!templatesMatch && (
              <LayoutHeader>
                <TopNav />
              </LayoutHeader>
            )}
            <LayoutContent>
              <PrivateRouter />
            </LayoutContent>
          </LayoutBody>
        </LayoutScreen>
      </LaunchDarklyIdentifier>
    </AnalyticsIdentifier>
  );
};

/*
  GateKeeper waits for auth to load (Auth0) then renders Private or Public routes
*/
const GateKeeper = () => {
  const { isLoading, isAuthenticated } = useAuthentication();

  if (isLoading) return null;

  /* we may need to accommodate authenticated users accessing public routes too */
  if (isAuthenticated) {
    return (
      <AuthorizationProvider>
        <Sentry>
          <Private />
        </Sentry>
      </AuthorizationProvider>
    );
  }

  return <Public />;
};

const App = () => (
  <Providers>
    <GateKeeper />
  </Providers>
);

export default App;

// http://localhost:3000/?code=8IbBnokoEITFIW2KHORj_l6nHImJUHixbfxy2-z1jIEvJ&state=NUwtOGJmOGF0NDRHMVZnaEJNMH5YaVhmdTJrZWlHcHJMRzE3Wkw4fkRZaQ%3D%3D
// http://localhost:3000/employer/join?inviteToken=e4f64559-c2c0-48ae-8d3b-d93486b554a8
