import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Date: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  cultureHotspots?: Maybe<Array<Maybe<CultureHotspot>>>;
  culturePerception?: Maybe<CulturePerceptionResult>;
  divisions: Array<Division>;
  group?: Maybe<Group>;
  groupMember?: Maybe<GroupMember>;
  groupMembers?: Maybe<Array<Maybe<GroupMember>>>;
  groups?: Maybe<Array<Maybe<Group>>>;
  hireReportProcess: HireReportProcess;
  hireReportResult?: Maybe<HireReportResult>;
  messageTemplate?: Maybe<MessageTemplate>;
  messageTemplates?: Maybe<Array<Maybe<MessageTemplate>>>;
  myCourses?: Maybe<Array<Course>>;
  myProfile?: Maybe<User>;
  organisationTags: Array<OrganisationTag>;
  personalityAssessment?: Maybe<PersonalityAssessment>;
  personalityResults?: Maybe<Array<Maybe<PersonalityAssessmentResult>>>;
  sharePageLinks?: Maybe<Array<Maybe<SharePage>>>;
  systemTags: Array<SystemTag>;
  tagActivities: Array<TagActivity>;
};


export type QueryCultureHotspotsArgs = {
  campaignSk?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCulturePerceptionArgs = {
  assessmentSk: Scalars['String']['input'];
  filters?: InputMaybe<Array<InputMaybe<CulturePerceptionFilterInput>>>;
};


export type QueryGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGroupMemberArgs = {
  input?: InputMaybe<GroupMemberInput>;
};


export type QueryGroupMembersArgs = {
  input?: InputMaybe<GroupMembersInput>;
};


export type QueryGroupsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHireReportProcessArgs = {
  params: ReportInput;
};


export type QueryHireReportResultArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPersonalityResultsArgs = {
  filters?: InputMaybe<Array<InputMaybe<PersonalityResultsQueryInput>>>;
};


export type QueryTagActivitiesArgs = {
  filter: TagActivityFilter;
};

export type CultureHotspot = {
  __typename?: 'CultureHotspot';
  aggregateFilter?: Maybe<Scalars['String']['output']>;
  aggregation?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['JSON']['output']>;
  desired?: Maybe<Scalars['JSON']['output']>;
  hasParent?: Maybe<Scalars['Boolean']['output']>;
  pk?: Maybe<Scalars['String']['output']>;
  responseCount?: Maybe<Scalars['Int']['output']>;
  sk?: Maybe<Scalars['String']['output']>;
};

export type CulturePerceptionFilterInput = {
  filterType?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CulturePerceptionResult = {
  __typename?: 'CulturePerceptionResult';
  completedUsersCount?: Maybe<Scalars['Int']['output']>;
  perceptionAverage?: Maybe<AssessmentPerception>;
  perceptionList?: Maybe<Array<Maybe<AssessmentPerception>>>;
  totalUsersCompleted?: Maybe<Scalars['Int']['output']>;
};

export type AssessmentPerception = {
  __typename?: 'AssessmentPerception';
  customerQuality?: Maybe<Scalars['Float']['output']>;
  financial?: Maybe<Scalars['Float']['output']>;
  jobEngagement?: Maybe<Scalars['Float']['output']>;
  jobSatisfaction?: Maybe<Scalars['Float']['output']>;
  learningGrowth?: Maybe<Scalars['Float']['output']>;
  operational?: Maybe<Scalars['Float']['output']>;
  overallClimate?: Maybe<Scalars['Float']['output']>;
  turnoverIntention?: Maybe<Scalars['Float']['output']>;
  workMotivation?: Maybe<Scalars['Float']['output']>;
};

export type Division = {
  __typename?: 'Division';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organisationId: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Group = {
  __typename?: 'Group';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  memberCount?: Maybe<Scalars['Int']['output']>;
  members?: Maybe<Array<Maybe<User>>>;
  name: Scalars['String']['output'];
  organisationId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

export type GroupMemberInput = {
  groupId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  memberType?: InputMaybe<MemberType>;
};

export enum MemberType {
  Candidate = 'candidate',
  Employer = 'employer'
}

export type GroupMember = {
  __typename?: 'GroupMember';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  member?: Maybe<User>;
  memberId: Scalars['ID']['output'];
  memberType?: Maybe<MemberType>;
  organisationId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GroupMembersInput = {
  groupId: Scalars['ID']['input'];
  memberType?: InputMaybe<MemberType>;
};

export type ReportInput = {
  endDate: Scalars['String']['input'];
  hiringTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate: Scalars['String']['input'];
  timezoneOffset: Scalars['Int']['input'];
};

export type HireReportProcess = {
  __typename?: 'HireReportProcess';
  id: Scalars['ID']['output'];
};

export type HireReportResult = {
  __typename?: 'HireReportResult';
  export: HireReportExport;
  id: Scalars['ID']['output'];
  report: HireReportStats;
};

export type HireReportExport = {
  __typename?: 'HireReportExport';
  csvUrl: Scalars['String']['output'];
  noOfEntries: Scalars['Int']['output'];
};

export type HireReportStats = {
  __typename?: 'HireReportStats';
  aggregates: HireReport;
  applicationSources: HireReportApplicationSources;
};

export type HireReport = {
  __typename?: 'HireReport';
  avgTimeToFillInDays: Scalars['Float']['output'];
  avgTimeToHireInDays: Scalars['Float']['output'];
  jobsClosedInPeriod: Scalars['Int']['output'];
  jobsOpenAtEndDate: Scalars['Int']['output'];
  jobsOpenAtStartDate: Scalars['Int']['output'];
  jobsOpenedInPeriod: Scalars['Int']['output'];
  totalApplications: Scalars['Int']['output'];
  totalHires: Scalars['Int']['output'];
};

export type HireReportApplicationSources = {
  __typename?: 'HireReportApplicationSources';
  all: Array<HireReportApplicationSourceCount>;
  hired: Array<HireReportApplicationSourceCount>;
};

export type HireReportApplicationSourceCount = {
  __typename?: 'HireReportApplicationSourceCount';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organisationId?: Maybe<Scalars['ID']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Course = {
  __typename?: 'Course';
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OrganisationTag = {
  __typename?: 'OrganisationTag';
  assignedCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  tagCategory?: Maybe<Scalars['String']['output']>;
};

export type PersonalityAssessment = {
  __typename?: 'PersonalityAssessment';
  answers: Array<PersonalityAssessmentAnswer>;
  catchPhrase: Scalars['String']['output'];
  characteristics: Array<Maybe<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  result: Scalars['String']['output'];
};

export type PersonalityAssessmentAnswer = {
  __typename?: 'PersonalityAssessmentAnswer';
  answer: Scalars['String']['output'];
  questionId: Scalars['String']['output'];
};

export type PersonalityResultsQueryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PersonalityAssessmentResult = {
  __typename?: 'PersonalityAssessmentResult';
  completedDate?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  personalityType?: Maybe<Scalars['String']['output']>;
};

export type SharePage = {
  __typename?: 'SharePage';
  dateShared?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  shareContent?: Maybe<Scalars['String']['output']>;
  sharer?: Maybe<Scalars['String']['output']>;
  sharerEmail?: Maybe<Scalars['String']['output']>;
  sk?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SystemTag = {
  __typename?: 'SystemTag';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type TagActivityFilter = {
  candidateId?: InputMaybe<Array<Scalars['ID']['input']>>;
  category?: InputMaybe<TagCategory>;
  listingId?: InputMaybe<Scalars['ID']['input']>;
  tagId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum TagCategory {
  Activity = 'ACTIVITY',
  Candidate = 'CANDIDATE'
}

export type TagActivity = {
  __typename?: 'TagActivity';
  action: TagActivityAction;
  actionedByUser?: Maybe<TagActivityActionedByUser>;
  history: Array<TagActivityHistory>;
  notes?: Maybe<Scalars['String']['output']>;
  tag: Tag;
  targetApplicant?: Maybe<Applicant>;
};

export enum TagActivityAction {
  Assigned = 'ASSIGNED',
  Unassigned = 'UNASSIGNED'
}

export type TagActivityActionedByUser = {
  __typename?: 'TagActivityActionedByUser';
  timestamp?: Maybe<Scalars['Date']['output']>;
  user: User;
};

export type TagActivityHistory = {
  __typename?: 'TagActivityHistory';
  action: TagActivityAction;
  actionedByUser?: Maybe<TagActivityActionedByUser>;
  notes?: Maybe<Scalars['String']['output']>;
};

export type Tag = OrganisationTag | SystemTag;

export type Applicant = {
  __typename?: 'Applicant';
  candidateId: Scalars['ID']['output'];
  listingId: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteOrganisationTag?: Maybe<DeleteOrganisationTagMutationResult>;
  expireShareLink?: Maybe<Scalars['String']['output']>;
  groupAssignUser?: Maybe<GroupMemberResponse>;
  groupCreate?: Maybe<GroupResponse>;
  groupDelete?: Maybe<GroupResponse>;
  groupEdit?: Maybe<GroupResponse>;
  groupUnassignUser?: Maybe<GroupMemberResponse>;
  messageTemplateCreate?: Maybe<MessageTemplateMutationResult>;
  messageTemplateDelete?: Maybe<MessageTemplateMutationResult>;
  messageTemplateUpdate?: Maybe<MessageTemplateMutationResult>;
  organisationTagActivitySet?: Maybe<OrganisationTagActivityMutationResult>;
  organisationTagCreate?: Maybe<OrganisationTagMutationResult>;
  personalityAssessmentInviteMembers?: Maybe<PersonalityAssessmentInviteMembers>;
  personalityAssessmentSaveAnswers?: Maybe<PersonalityAssessment>;
  shareCultureReport?: Maybe<SharePage>;
  systemTagActivitySet?: Maybe<TagActivityMutationResult>;
};


export type MutationDeleteOrganisationTagArgs = {
  input?: InputMaybe<DeleteOrganisationTagInput>;
};


export type MutationExpireShareLinkArgs = {
  sk?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGroupAssignUserArgs = {
  input?: InputMaybe<GroupAssignUserInput>;
};


export type MutationGroupCreateArgs = {
  input?: InputMaybe<GroupCreateInput>;
};


export type MutationGroupDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGroupEditArgs = {
  input?: InputMaybe<GroupEditInput>;
};


export type MutationGroupUnassignUserArgs = {
  input?: InputMaybe<GroupAssignUserInput>;
};


export type MutationMessageTemplateCreateArgs = {
  input: MessageTemplateCreateInput;
};


export type MutationMessageTemplateDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMessageTemplateUpdateArgs = {
  input?: InputMaybe<MessageTemplateUpdateInput>;
};


export type MutationOrganisationTagActivitySetArgs = {
  input: OrganisationTagActivityInput;
};


export type MutationOrganisationTagCreateArgs = {
  input: OrganisationTagCreateInput;
};


export type MutationPersonalityAssessmentInviteMembersArgs = {
  input: PersonalityAssessmentInviteMembersInput;
};


export type MutationPersonalityAssessmentSaveAnswersArgs = {
  input: PersonalityAssessmentAnswersInput;
};


export type MutationShareCultureReportArgs = {
  input: SharePageInput;
};


export type MutationSystemTagActivitySetArgs = {
  input: SystemTagActivityInput;
};

export type DeleteOrganisationTagInput = {
  tagId: Scalars['ID']['input'];
};

export type DeleteOrganisationTagMutationResult = {
  __typename?: 'DeleteOrganisationTagMutationResult';
  errors?: Maybe<Array<Maybe<DeleteOrganisationTagMutationError>>>;
  tag?: Maybe<OrganisationTag>;
};

export type DeleteOrganisationTagMutationError = {
  __typename?: 'DeleteOrganisationTagMutationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type GroupAssignUserInput = {
  groupId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  memberType?: InputMaybe<MemberType>;
};

export type GroupMemberResponse = {
  __typename?: 'GroupMemberResponse';
  errors?: Maybe<Array<Maybe<GroupMemberMutationError>>>;
  groupMember?: Maybe<GroupMember>;
};

export type GroupMemberMutationError = {
  __typename?: 'GroupMemberMutationError';
  code?: Maybe<GroupMemberMutationErrorCode>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum GroupMemberMutationErrorCode {
  DuplicateGroupMember = 'DUPLICATE_GROUP_MEMBER',
  GroupMemberNotFound = 'GROUP_MEMBER_NOT_FOUND'
}

export type GroupCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type GroupResponse = {
  __typename?: 'GroupResponse';
  errors?: Maybe<Array<Maybe<GroupMutationError>>>;
  group?: Maybe<Group>;
};

export type GroupMutationError = {
  __typename?: 'GroupMutationError';
  code?: Maybe<GroupMutationErrorCode>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum GroupMutationErrorCode {
  DuplicateGroup = 'DUPLICATE_GROUP',
  MaxGroupCharactersReached = 'MAX_GROUP_CHARACTERS_REACHED',
  NotAlphanumericGroup = 'NOT_ALPHANUMERIC_GROUP'
}

export type GroupEditInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MessageTemplateCreateInput = {
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type MessageTemplateMutationResult = {
  __typename?: 'MessageTemplateMutationResult';
  errors: Array<Maybe<MessageTemplateMutationError>>;
  messageTemplate?: Maybe<MessageTemplate>;
};

export type MessageTemplateMutationError = {
  __typename?: 'MessageTemplateMutationError';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MessageTemplateUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationTagActivityInput = {
  activities: Array<OrganisationTagCoreActivityInput>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  listingId?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationTagCoreActivityInput = {
  action: TagActivityAction;
  tagId: Scalars['ID']['input'];
};

export type OrganisationTagActivityMutationResult = {
  __typename?: 'OrganisationTagActivityMutationResult';
  errors: Array<TagActivityMutationError>;
  tagActivities?: Maybe<Array<Maybe<TagActivity>>>;
};

export type TagActivityMutationError = {
  __typename?: 'TagActivityMutationError';
  code?: Maybe<TagActivityMutationErrorCode>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum TagActivityMutationErrorCode {
  InvalidTag = 'INVALID_TAG',
  MaxTagsAssigned = 'MAX_TAGS_ASSIGNED'
}

export type OrganisationTagCreateInput = {
  category: TagCategory;
  label: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrganisationTagMutationResult = {
  __typename?: 'OrganisationTagMutationResult';
  errors: Array<CreateTagError>;
  tag?: Maybe<Tag>;
};

export type CreateTagError = {
  __typename?: 'CreateTagError';
  code?: Maybe<CreateTagErrorCode>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum CreateTagErrorCode {
  DuplicateTag = 'DUPLICATE_TAG',
  MaxTagCharactersReached = 'MAX_TAG_CHARACTERS_REACHED',
  MaxTagsReached = 'MAX_TAGS_REACHED',
  NotAlphanumericTag = 'NOT_ALPHANUMERIC_TAG'
}

export type PersonalityAssessmentInviteMembersInput = {
  emailContent: Scalars['String']['input'];
  filters: Array<PersonalityResultsQueryInput>;
  fromName: Scalars['String']['input'];
  fromTitle: Scalars['String']['input'];
};

export type PersonalityAssessmentInviteMembers = {
  __typename?: 'PersonalityAssessmentInviteMembers';
  error?: Maybe<Scalars['String']['output']>;
};

export type PersonalityAssessmentAnswersInput = {
  answers: Array<PersonalityAssessmentAnswerInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PersonalityAssessmentAnswerInput = {
  answer: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
};

export type SharePageInput = {
  campaignSk: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  refCampaignSk?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type SystemTagActivityInput = {
  action: TagActivityAction;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  listingId?: InputMaybe<Scalars['ID']['input']>;
  tagId: SystemTagId;
};

export enum SystemTagId {
  Favourite = 'FAVOURITE'
}

export type TagActivityMutationResult = {
  __typename?: 'TagActivityMutationResult';
  errors: Array<UserError>;
  tagActivity?: Maybe<TagActivity>;
};

export type UserError = {
  __typename?: 'UserError';
  field?: Maybe<Array<Scalars['String']['output']>>;
  message: Scalars['String']['output'];
};

export type CulturePerceptionQueryVariables = Exact<{
  assessmentSk: Scalars['String']['input'];
  filters?: InputMaybe<Array<InputMaybe<CulturePerceptionFilterInput>> | InputMaybe<CulturePerceptionFilterInput>>;
}>;


export type CulturePerceptionQuery = { __typename?: 'Query', culturePerception?: { __typename?: 'CulturePerceptionResult', completedUsersCount?: number | null, totalUsersCompleted?: number | null, perceptionAverage?: { __typename?: 'AssessmentPerception', financial?: number | null, operational?: number | null, learningGrowth?: number | null, customerQuality?: number | null, jobEngagement?: number | null, jobSatisfaction?: number | null, turnoverIntention?: number | null, workMotivation?: number | null, overallClimate?: number | null } | null, perceptionList?: Array<{ __typename?: 'AssessmentPerception', financial?: number | null, operational?: number | null, learningGrowth?: number | null, customerQuality?: number | null, jobEngagement?: number | null, jobSatisfaction?: number | null, turnoverIntention?: number | null, workMotivation?: number | null, overallClimate?: number | null } | null> | null } | null };

export type GetCultureHotspotsQueryVariables = Exact<{
  campaignSk?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCultureHotspotsQuery = { __typename?: 'Query', cultureHotspots?: Array<{ __typename?: 'CultureHotspot', pk?: string | null, sk?: string | null, campaignId?: string | null, campaignName?: string | null, responseCount?: number | null, aggregation?: string | null, current?: any | null, desired?: any | null, hasParent?: boolean | null, aggregateFilter?: string | null } | null> | null };

export type GetMyCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyCoursesQuery = { __typename?: 'Query', myCourses?: Array<{ __typename?: 'Course', id: string, title?: string | null, url?: string | null, imageUrl?: string | null }> | null };

export type GetDivisionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDivisionsQuery = { __typename?: 'Query', divisions: Array<{ __typename?: 'Division', id: string, organisationId: string, name: string, description?: string | null, parentId?: string | null, isDefault?: boolean | null, createdAt?: any | null, updatedAt?: any | null }> };

export type GetGroupsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetGroupsQuery = { __typename?: 'Query', groups?: Array<{ __typename?: 'Group', organisationId: string, id: string, name: string, memberCount?: number | null, description?: string | null, members?: Array<{ __typename?: 'User', id: string } | null> | null } | null> | null };

export type GetGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetGroupQuery = { __typename?: 'Query', group?: { __typename?: 'Group', organisationId: string, name: string, memberCount?: number | null, description?: string | null, members?: Array<{ __typename?: 'User', id: string } | null> | null } | null };

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', groupCreate?: { __typename?: 'GroupResponse', group?: { __typename?: 'Group', id: string, organisationId: string, name: string, description?: string | null, members?: Array<{ __typename?: 'User', id: string } | null> | null } | null, errors?: Array<{ __typename?: 'GroupMutationError', message?: string | null } | null> | null } | null };

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', groupEdit?: { __typename?: 'GroupResponse', group?: { __typename?: 'Group', id: string, organisationId: string, name: string, description?: string | null, members?: Array<{ __typename?: 'User', id: string } | null> | null } | null, errors?: Array<{ __typename?: 'GroupMutationError', message?: string | null } | null> | null } | null };

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', groupDelete?: { __typename?: 'GroupResponse', group?: { __typename?: 'Group', id: string, organisationId: string, name: string, description?: string | null, members?: Array<{ __typename?: 'User', id: string } | null> | null } | null } | null };

export type GroupAssignUserMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
}>;


export type GroupAssignUserMutation = { __typename?: 'Mutation', groupAssignUser?: { __typename?: 'GroupMemberResponse', groupMember?: { __typename?: 'GroupMember', groupId: string, memberId: string, memberType?: MemberType | null, organisationId: string } | null, errors?: Array<{ __typename?: 'GroupMemberMutationError', message?: string | null } | null> | null } | null };

export type GroupUnassignUserMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
}>;


export type GroupUnassignUserMutation = { __typename?: 'Mutation', groupUnassignUser?: { __typename?: 'GroupMemberResponse', groupMember?: { __typename?: 'GroupMember', groupId: string, memberId: string, memberType?: MemberType | null, organisationId: string } | null, errors?: Array<{ __typename?: 'GroupMemberMutationError', message?: string | null } | null> | null } | null };

export type GroupMembersQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type GroupMembersQuery = { __typename?: 'Query', groupMembers?: Array<{ __typename?: 'GroupMember', memberId: string, organisationId: string, groupId: string, memberType?: MemberType | null } | null> | null };

export type HireReportProcessQueryVariables = Exact<{
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  hiringTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  timezoneOffset: Scalars['Int']['input'];
}>;


export type HireReportProcessQuery = { __typename?: 'Query', hireReportProcess: { __typename?: 'HireReportProcess', id: string } };

export type HireReportResultQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type HireReportResultQuery = { __typename?: 'Query', hireReportResult?: { __typename?: 'HireReportResult', id: string, report: { __typename?: 'HireReportStats', aggregates: { __typename?: 'HireReport', jobsOpenAtStartDate: number, jobsOpenAtEndDate: number, jobsOpenedInPeriod: number, jobsClosedInPeriod: number, totalApplications: number, totalHires: number, avgTimeToFillInDays: number, avgTimeToHireInDays: number }, applicationSources: { __typename?: 'HireReportApplicationSources', all: Array<{ __typename?: 'HireReportApplicationSourceCount', name: string, count: number }>, hired: Array<{ __typename?: 'HireReportApplicationSourceCount', name: string, count: number }> } }, export: { __typename?: 'HireReportExport', csvUrl: string, noOfEntries: number } } | null };

export type GetMessageTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMessageTemplateQuery = { __typename?: 'Query', messageTemplate?: { __typename?: 'MessageTemplate', organisationId?: string | null, id?: string | null, name?: string | null, description?: string | null, subject?: string | null, content?: string | null, enabled?: boolean | null } | null };

export type GetMessageTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessageTemplatesQuery = { __typename?: 'Query', messageTemplates?: Array<{ __typename?: 'MessageTemplate', id?: string | null, organisationId?: string | null, name?: string | null, description?: string | null, subject?: string | null, content?: string | null, enabled?: boolean | null } | null> | null };

export type CreateMessageTemplateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  content: Scalars['String']['input'];
}>;


export type CreateMessageTemplateMutation = { __typename?: 'Mutation', messageTemplateCreate?: { __typename?: 'MessageTemplateMutationResult', messageTemplate?: { __typename?: 'MessageTemplate', id?: string | null, organisationId?: string | null, name?: string | null, subject?: string | null, content?: string | null, description?: string | null, enabled?: boolean | null } | null, errors: Array<{ __typename?: 'MessageTemplateMutationError', message?: string | null } | null> } | null };

export type UpdateMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateMessageTemplateMutation = { __typename?: 'Mutation', messageTemplateUpdate?: { __typename?: 'MessageTemplateMutationResult', messageTemplate?: { __typename?: 'MessageTemplate', id?: string | null, organisationId?: string | null, name?: string | null, description?: string | null, subject?: string | null, content?: string | null, enabled?: boolean | null } | null, errors: Array<{ __typename?: 'MessageTemplateMutationError', message?: string | null } | null> } | null };

export type DeleteMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMessageTemplateMutation = { __typename?: 'Mutation', messageTemplateDelete?: { __typename?: 'MessageTemplateMutationResult', messageTemplate?: { __typename?: 'MessageTemplate', id?: string | null, organisationId?: string | null, name?: string | null, description?: string | null, subject?: string | null, content?: string | null, enabled?: boolean | null } | null } | null };

export type PersonalityAssessmentQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonalityAssessmentQuery = { __typename?: 'Query', personalityAssessment?: { __typename?: 'PersonalityAssessment', id?: string | null, result: string, characteristics: Array<string | null>, catchPhrase: string, answers: Array<{ __typename?: 'PersonalityAssessmentAnswer', questionId: string, answer: string }> } | null };

export type PersonalityAssessmentSaveAnswersMutationVariables = Exact<{
  input: PersonalityAssessmentAnswersInput;
}>;


export type PersonalityAssessmentSaveAnswersMutation = { __typename?: 'Mutation', personalityAssessmentSaveAnswers?: { __typename?: 'PersonalityAssessment', id?: string | null } | null };

export type PersonalityAssessmentInviteMembersMutationVariables = Exact<{
  input: PersonalityAssessmentInviteMembersInput;
}>;


export type PersonalityAssessmentInviteMembersMutation = { __typename?: 'Mutation', personalityAssessmentInviteMembers?: { __typename?: 'PersonalityAssessmentInviteMembers', error?: string | null } | null };

export type GetPersonalityResultsQueriesQueryVariables = Exact<{
  filters?: InputMaybe<Array<InputMaybe<PersonalityResultsQueryInput>> | InputMaybe<PersonalityResultsQueryInput>>;
}>;


export type GetPersonalityResultsQueriesQuery = { __typename?: 'Query', personalityResults?: Array<{ __typename?: 'PersonalityAssessmentResult', personalityType?: string | null, name?: string | null, firstName?: string | null, lastName?: string | null, completedDate?: string | null, division?: string | null, jobTitle?: string | null } | null> | null };

export type ShareCultureReportMutationVariables = Exact<{
  input: SharePageInput;
}>;


export type ShareCultureReportMutation = { __typename?: 'Mutation', shareCultureReport?: { __typename?: 'SharePage', link?: string | null } | null };

export type ExpireShareLinkMutationVariables = Exact<{
  sk: Scalars['String']['input'];
}>;


export type ExpireShareLinkMutation = { __typename?: 'Mutation', expireShareLink?: string | null };

export type SharePageLinksQueryVariables = Exact<{ [key: string]: never; }>;


export type SharePageLinksQuery = { __typename?: 'Query', sharePageLinks?: Array<{ __typename?: 'SharePage', sk?: string | null, dateShared?: string | null, link?: string | null, title?: string | null, expiryDate?: string | null, sharer?: string | null, sharerEmail?: string | null, shareContent?: string | null, type?: string | null } | null> | null };

export type GetTagActivitiesWithoutHistoryQueryVariables = Exact<{
  filter: TagActivityFilter;
}>;


export type GetTagActivitiesWithoutHistoryQuery = { __typename?: 'Query', tagActivities: Array<{ __typename?: 'TagActivity', action: TagActivityAction, tag: { __typename: 'OrganisationTag', id: string, label?: string | null } | { __typename: 'SystemTag', id: string }, actionedByUser?: { __typename?: 'TagActivityActionedByUser', timestamp?: any | null, user: { __typename?: 'User', id: string, firstName: string, lastName: string } } | null, targetApplicant?: { __typename?: 'Applicant', candidateId: string } | null }> };

export type GetOrganisationTagActivitiesQueryVariables = Exact<{
  filter: TagActivityFilter;
}>;


export type GetOrganisationTagActivitiesQuery = { __typename?: 'Query', tagActivities: Array<{ __typename?: 'TagActivity', notes?: string | null, action: TagActivityAction, tag: { __typename: 'OrganisationTag', id: string, label?: string | null } | { __typename: 'SystemTag' }, actionedByUser?: { __typename?: 'TagActivityActionedByUser', timestamp?: any | null, user: { __typename?: 'User', id: string, firstName: string, lastName: string } } | null, targetApplicant?: { __typename?: 'Applicant', candidateId: string } | null }> };

export type GetTagActivitiesQueryVariables = Exact<{
  filter: TagActivityFilter;
}>;


export type GetTagActivitiesQuery = { __typename?: 'Query', tagActivities: Array<{ __typename?: 'TagActivity', action: TagActivityAction, notes?: string | null, tag: { __typename: 'OrganisationTag', id: string, label?: string | null, assignedCount?: number | null } | { __typename: 'SystemTag', id: string }, actionedByUser?: { __typename?: 'TagActivityActionedByUser', timestamp?: any | null, user: { __typename?: 'User', id: string, firstName: string, lastName: string } } | null, targetApplicant?: { __typename?: 'Applicant', candidateId: string } | null, history: Array<{ __typename?: 'TagActivityHistory', action: TagActivityAction, notes?: string | null, actionedByUser?: { __typename?: 'TagActivityActionedByUser', timestamp?: any | null, user: { __typename?: 'User', id: string, firstName: string, lastName: string } } | null }> }> };

export type OrganisationTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationTagsQuery = { __typename?: 'Query', organisationTags: Array<{ __typename?: 'OrganisationTag', id: string, label?: string | null, tagCategory?: string | null, assignedCount?: number | null }> };

export type SystemTagActivitySetMutationVariables = Exact<{
  input: SystemTagActivityInput;
}>;


export type SystemTagActivitySetMutation = { __typename?: 'Mutation', systemTagActivitySet?: { __typename?: 'TagActivityMutationResult', tagActivity?: { __typename?: 'TagActivity', action: TagActivityAction, tag: { __typename?: 'OrganisationTag' } | { __typename?: 'SystemTag', id: string, label?: string | null }, targetApplicant?: { __typename?: 'Applicant', listingId: string, candidateId: string } | null, actionedByUser?: { __typename?: 'TagActivityActionedByUser', user: { __typename?: 'User', id: string } } | null } | null, errors: Array<{ __typename?: 'UserError', message: string }> } | null };

export type OrganisationTagCreateMutationVariables = Exact<{
  input: OrganisationTagCreateInput;
}>;


export type OrganisationTagCreateMutation = { __typename?: 'Mutation', organisationTagCreate?: { __typename?: 'OrganisationTagMutationResult', tag?: { __typename: 'OrganisationTag', id: string, label?: string | null } | { __typename: 'SystemTag' } | null, errors: Array<{ __typename?: 'CreateTagError', message?: string | null, code?: CreateTagErrorCode | null }> } | null };

export type OrganisationTagActivitySetMutationVariables = Exact<{
  input: OrganisationTagActivityInput;
}>;


export type OrganisationTagActivitySetMutation = { __typename?: 'Mutation', organisationTagActivitySet?: { __typename?: 'OrganisationTagActivityMutationResult', tagActivities?: Array<{ __typename?: 'TagActivity', notes?: string | null, action: TagActivityAction, tag: { __typename?: 'OrganisationTag', id: string, label?: string | null } | { __typename?: 'SystemTag', id: string, label?: string | null }, targetApplicant?: { __typename?: 'Applicant', listingId: string, candidateId: string } | null, actionedByUser?: { __typename?: 'TagActivityActionedByUser', timestamp?: any | null, user: { __typename?: 'User', id: string } } | null } | null> | null, errors: Array<{ __typename?: 'TagActivityMutationError', message?: string | null }> } | null };

export type DeleteOrganisationTagMutationVariables = Exact<{
  input: DeleteOrganisationTagInput;
}>;


export type DeleteOrganisationTagMutation = { __typename?: 'Mutation', deleteOrganisationTag?: { __typename?: 'DeleteOrganisationTagMutationResult', tag?: { __typename: 'OrganisationTag', id: string, label?: string | null } | null, errors?: Array<{ __typename?: 'DeleteOrganisationTagMutationError', message?: string | null } | null> | null } | null };


export const CulturePerceptionDocument = gql`
    query culturePerception($assessmentSk: String!, $filters: [CulturePerceptionFilterInput]) {
  culturePerception(assessmentSk: $assessmentSk, filters: $filters) {
    perceptionAverage {
      financial
      operational
      learningGrowth
      customerQuality
      jobEngagement
      jobSatisfaction
      turnoverIntention
      workMotivation
      overallClimate
    }
    perceptionList {
      financial
      operational
      learningGrowth
      customerQuality
      jobEngagement
      jobSatisfaction
      turnoverIntention
      workMotivation
      overallClimate
    }
    completedUsersCount
    totalUsersCompleted
  }
}
    `;

/**
 * __useCulturePerceptionQuery__
 *
 * To run a query within a React component, call `useCulturePerceptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCulturePerceptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCulturePerceptionQuery({
 *   variables: {
 *      assessmentSk: // value for 'assessmentSk'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCulturePerceptionQuery(baseOptions: Apollo.QueryHookOptions<CulturePerceptionQuery, CulturePerceptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CulturePerceptionQuery, CulturePerceptionQueryVariables>(CulturePerceptionDocument, options);
      }
export function useCulturePerceptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CulturePerceptionQuery, CulturePerceptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CulturePerceptionQuery, CulturePerceptionQueryVariables>(CulturePerceptionDocument, options);
        }
export type CulturePerceptionQueryHookResult = ReturnType<typeof useCulturePerceptionQuery>;
export type CulturePerceptionLazyQueryHookResult = ReturnType<typeof useCulturePerceptionLazyQuery>;
export type CulturePerceptionQueryResult = Apollo.QueryResult<CulturePerceptionQuery, CulturePerceptionQueryVariables>;
export const GetCultureHotspotsDocument = gql`
    query getCultureHotspots($campaignSk: String, $filter: String) {
  cultureHotspots(campaignSk: $campaignSk, filter: $filter) {
    pk
    sk
    campaignId
    campaignName
    responseCount
    aggregation
    current
    desired
    responseCount
    hasParent
    aggregateFilter
  }
}
    `;

/**
 * __useGetCultureHotspotsQuery__
 *
 * To run a query within a React component, call `useGetCultureHotspotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCultureHotspotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCultureHotspotsQuery({
 *   variables: {
 *      campaignSk: // value for 'campaignSk'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCultureHotspotsQuery(baseOptions?: Apollo.QueryHookOptions<GetCultureHotspotsQuery, GetCultureHotspotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCultureHotspotsQuery, GetCultureHotspotsQueryVariables>(GetCultureHotspotsDocument, options);
      }
export function useGetCultureHotspotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCultureHotspotsQuery, GetCultureHotspotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCultureHotspotsQuery, GetCultureHotspotsQueryVariables>(GetCultureHotspotsDocument, options);
        }
export type GetCultureHotspotsQueryHookResult = ReturnType<typeof useGetCultureHotspotsQuery>;
export type GetCultureHotspotsLazyQueryHookResult = ReturnType<typeof useGetCultureHotspotsLazyQuery>;
export type GetCultureHotspotsQueryResult = Apollo.QueryResult<GetCultureHotspotsQuery, GetCultureHotspotsQueryVariables>;
export const GetMyCoursesDocument = gql`
    query GetMyCourses {
  myCourses {
    id
    title
    url
    imageUrl
  }
}
    `;

/**
 * __useGetMyCoursesQuery__
 *
 * To run a query within a React component, call `useGetMyCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyCoursesQuery, GetMyCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCoursesQuery, GetMyCoursesQueryVariables>(GetMyCoursesDocument, options);
      }
export function useGetMyCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCoursesQuery, GetMyCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCoursesQuery, GetMyCoursesQueryVariables>(GetMyCoursesDocument, options);
        }
export type GetMyCoursesQueryHookResult = ReturnType<typeof useGetMyCoursesQuery>;
export type GetMyCoursesLazyQueryHookResult = ReturnType<typeof useGetMyCoursesLazyQuery>;
export type GetMyCoursesQueryResult = Apollo.QueryResult<GetMyCoursesQuery, GetMyCoursesQueryVariables>;
export const GetDivisionsDocument = gql`
    query getDivisions {
  divisions {
    id
    organisationId
    name
    description
    parentId
    isDefault
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetDivisionsQuery__
 *
 * To run a query within a React component, call `useGetDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDivisionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDivisionsQuery(baseOptions?: Apollo.QueryHookOptions<GetDivisionsQuery, GetDivisionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GetDivisionsDocument, options);
      }
export function useGetDivisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDivisionsQuery, GetDivisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GetDivisionsDocument, options);
        }
export type GetDivisionsQueryHookResult = ReturnType<typeof useGetDivisionsQuery>;
export type GetDivisionsLazyQueryHookResult = ReturnType<typeof useGetDivisionsLazyQuery>;
export type GetDivisionsQueryResult = Apollo.QueryResult<GetDivisionsQuery, GetDivisionsQueryVariables>;
export const GetGroupsDocument = gql`
    query getGroups($search: String) {
  groups(search: $search) {
    organisationId
    id
    name
    memberCount
    members {
      id
    }
    description
  }
}
    `;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetGroupDocument = gql`
    query getGroup($id: ID!) {
  group(id: $id) {
    organisationId
    name
    memberCount
    members {
      id
    }
    description
  }
}
    `;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupQuery(baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
      }
export function useGetGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
        }
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<typeof useGetGroupLazyQuery>;
export type GetGroupQueryResult = Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>;
export const CreateGroupDocument = gql`
    mutation createGroup($name: String!, $description: String) {
  groupCreate(input: {name: $name, description: $description}) {
    group {
      id
      organisationId
      name
      members {
        id
      }
      description
    }
    errors {
      message
    }
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation updateGroup($id: ID!, $name: String!, $description: String) {
  groupEdit(input: {id: $id, name: $name, description: $description}) {
    group {
      id
      organisationId
      name
      members {
        id
      }
      description
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($id: ID!) {
  groupDelete(id: $id) {
    group {
      id
      organisationId
      name
      members {
        id
      }
      description
    }
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const GroupAssignUserDocument = gql`
    mutation groupAssignUser($groupId: ID!, $memberId: ID!) {
  groupAssignUser(input: {groupId: $groupId, memberId: $memberId}) {
    groupMember {
      groupId
      memberId
      memberType
      organisationId
    }
    errors {
      message
    }
  }
}
    `;
export type GroupAssignUserMutationFn = Apollo.MutationFunction<GroupAssignUserMutation, GroupAssignUserMutationVariables>;

/**
 * __useGroupAssignUserMutation__
 *
 * To run a mutation, you first call `useGroupAssignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupAssignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupAssignUserMutation, { data, loading, error }] = useGroupAssignUserMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGroupAssignUserMutation(baseOptions?: Apollo.MutationHookOptions<GroupAssignUserMutation, GroupAssignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupAssignUserMutation, GroupAssignUserMutationVariables>(GroupAssignUserDocument, options);
      }
export type GroupAssignUserMutationHookResult = ReturnType<typeof useGroupAssignUserMutation>;
export type GroupAssignUserMutationResult = Apollo.MutationResult<GroupAssignUserMutation>;
export type GroupAssignUserMutationOptions = Apollo.BaseMutationOptions<GroupAssignUserMutation, GroupAssignUserMutationVariables>;
export const GroupUnassignUserDocument = gql`
    mutation groupUnassignUser($groupId: ID!, $memberId: ID!) {
  groupUnassignUser(input: {groupId: $groupId, memberId: $memberId}) {
    groupMember {
      groupId
      memberId
      memberType
      organisationId
    }
    errors {
      message
    }
  }
}
    `;
export type GroupUnassignUserMutationFn = Apollo.MutationFunction<GroupUnassignUserMutation, GroupUnassignUserMutationVariables>;

/**
 * __useGroupUnassignUserMutation__
 *
 * To run a mutation, you first call `useGroupUnassignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupUnassignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupUnassignUserMutation, { data, loading, error }] = useGroupUnassignUserMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGroupUnassignUserMutation(baseOptions?: Apollo.MutationHookOptions<GroupUnassignUserMutation, GroupUnassignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupUnassignUserMutation, GroupUnassignUserMutationVariables>(GroupUnassignUserDocument, options);
      }
export type GroupUnassignUserMutationHookResult = ReturnType<typeof useGroupUnassignUserMutation>;
export type GroupUnassignUserMutationResult = Apollo.MutationResult<GroupUnassignUserMutation>;
export type GroupUnassignUserMutationOptions = Apollo.BaseMutationOptions<GroupUnassignUserMutation, GroupUnassignUserMutationVariables>;
export const GroupMembersDocument = gql`
    query groupMembers($groupId: ID!) {
  groupMembers(input: {groupId: $groupId}) {
    memberId
    organisationId
    groupId
    memberType
  }
}
    `;

/**
 * __useGroupMembersQuery__
 *
 * To run a query within a React component, call `useGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupMembersQuery(baseOptions: Apollo.QueryHookOptions<GroupMembersQuery, GroupMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupMembersQuery, GroupMembersQueryVariables>(GroupMembersDocument, options);
      }
export function useGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupMembersQuery, GroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupMembersQuery, GroupMembersQueryVariables>(GroupMembersDocument, options);
        }
export type GroupMembersQueryHookResult = ReturnType<typeof useGroupMembersQuery>;
export type GroupMembersLazyQueryHookResult = ReturnType<typeof useGroupMembersLazyQuery>;
export type GroupMembersQueryResult = Apollo.QueryResult<GroupMembersQuery, GroupMembersQueryVariables>;
export const HireReportProcessDocument = gql`
    query hireReportProcess($startDate: String!, $endDate: String!, $hiringTeamIds: [String], $timezoneOffset: Int!) {
  hireReportProcess(params: {startDate: $startDate, endDate: $endDate, hiringTeamIds: $hiringTeamIds, timezoneOffset: $timezoneOffset}) {
    id
  }
}
    `;

/**
 * __useHireReportProcessQuery__
 *
 * To run a query within a React component, call `useHireReportProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useHireReportProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHireReportProcessQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      hiringTeamIds: // value for 'hiringTeamIds'
 *      timezoneOffset: // value for 'timezoneOffset'
 *   },
 * });
 */
export function useHireReportProcessQuery(baseOptions: Apollo.QueryHookOptions<HireReportProcessQuery, HireReportProcessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HireReportProcessQuery, HireReportProcessQueryVariables>(HireReportProcessDocument, options);
      }
export function useHireReportProcessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HireReportProcessQuery, HireReportProcessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HireReportProcessQuery, HireReportProcessQueryVariables>(HireReportProcessDocument, options);
        }
export type HireReportProcessQueryHookResult = ReturnType<typeof useHireReportProcessQuery>;
export type HireReportProcessLazyQueryHookResult = ReturnType<typeof useHireReportProcessLazyQuery>;
export type HireReportProcessQueryResult = Apollo.QueryResult<HireReportProcessQuery, HireReportProcessQueryVariables>;
export const HireReportResultDocument = gql`
    query hireReportResult($id: ID!) {
  hireReportResult(id: $id) {
    id
    report {
      aggregates {
        jobsOpenAtStartDate
        jobsOpenAtEndDate
        jobsOpenedInPeriod
        jobsClosedInPeriod
        totalApplications
        totalHires
        avgTimeToFillInDays
        avgTimeToHireInDays
      }
      applicationSources {
        all {
          name
          count
        }
        hired {
          name
          count
        }
      }
    }
    export {
      csvUrl
      noOfEntries
    }
  }
}
    `;

/**
 * __useHireReportResultQuery__
 *
 * To run a query within a React component, call `useHireReportResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useHireReportResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHireReportResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHireReportResultQuery(baseOptions: Apollo.QueryHookOptions<HireReportResultQuery, HireReportResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HireReportResultQuery, HireReportResultQueryVariables>(HireReportResultDocument, options);
      }
export function useHireReportResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HireReportResultQuery, HireReportResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HireReportResultQuery, HireReportResultQueryVariables>(HireReportResultDocument, options);
        }
export type HireReportResultQueryHookResult = ReturnType<typeof useHireReportResultQuery>;
export type HireReportResultLazyQueryHookResult = ReturnType<typeof useHireReportResultLazyQuery>;
export type HireReportResultQueryResult = Apollo.QueryResult<HireReportResultQuery, HireReportResultQueryVariables>;
export const GetMessageTemplateDocument = gql`
    query getMessageTemplate($id: ID!) {
  messageTemplate(id: $id) {
    organisationId
    id
    name
    description
    subject
    content
    enabled
  }
}
    `;

/**
 * __useGetMessageTemplateQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>(GetMessageTemplateDocument, options);
      }
export function useGetMessageTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>(GetMessageTemplateDocument, options);
        }
export type GetMessageTemplateQueryHookResult = ReturnType<typeof useGetMessageTemplateQuery>;
export type GetMessageTemplateLazyQueryHookResult = ReturnType<typeof useGetMessageTemplateLazyQuery>;
export type GetMessageTemplateQueryResult = Apollo.QueryResult<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>;
export const GetMessageTemplatesDocument = gql`
    query getMessageTemplates {
  messageTemplates {
    id
    organisationId
    name
    description
    subject
    content
    enabled
  }
}
    `;

/**
 * __useGetMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMessageTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
      }
export function useGetMessageTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
        }
export type GetMessageTemplatesQueryHookResult = ReturnType<typeof useGetMessageTemplatesQuery>;
export type GetMessageTemplatesLazyQueryHookResult = ReturnType<typeof useGetMessageTemplatesLazyQuery>;
export type GetMessageTemplatesQueryResult = Apollo.QueryResult<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>;
export const CreateMessageTemplateDocument = gql`
    mutation createMessageTemplate($name: String!, $description: String, $subject: String!, $content: String!) {
  messageTemplateCreate(input: {name: $name, description: $description, subject: $subject, content: $content, enabled: false}) {
    messageTemplate {
      id
      organisationId
      name
      subject
      content
      description
      enabled
    }
    errors {
      message
    }
  }
}
    `;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      subject: // value for 'subject'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(CreateMessageTemplateDocument, options);
      }
export type CreateMessageTemplateMutationHookResult = ReturnType<typeof useCreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationResult = Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;
export const UpdateMessageTemplateDocument = gql`
    mutation updateMessageTemplate($id: ID!, $name: String, $description: String, $subject: String, $content: String, $enabled: Boolean) {
  messageTemplateUpdate(input: {id: $id, name: $name, description: $description, subject: $subject, content: $content, enabled: $enabled}) {
    messageTemplate {
      id
      organisationId
      name
      description
      subject
      content
      enabled
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      subject: // value for 'subject'
 *      content: // value for 'content'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UpdateMessageTemplateDocument, options);
      }
export type UpdateMessageTemplateMutationHookResult = ReturnType<typeof useUpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationResult = Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;
export const DeleteMessageTemplateDocument = gql`
    mutation deleteMessageTemplate($id: ID!) {
  messageTemplateDelete(id: $id) {
    messageTemplate {
      id
      organisationId
      name
      description
      subject
      content
      enabled
    }
  }
}
    `;
export type DeleteMessageTemplateMutationFn = Apollo.MutationFunction<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;

/**
 * __useDeleteMessageTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageTemplateMutation, { data, loading, error }] = useDeleteMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>(DeleteMessageTemplateDocument, options);
      }
export type DeleteMessageTemplateMutationHookResult = ReturnType<typeof useDeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationResult = Apollo.MutationResult<DeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;
export const PersonalityAssessmentDocument = gql`
    query PersonalityAssessment {
  personalityAssessment {
    id
    result
    answers {
      questionId
      answer
    }
    characteristics
    catchPhrase
  }
}
    `;

/**
 * __usePersonalityAssessmentQuery__
 *
 * To run a query within a React component, call `usePersonalityAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalityAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalityAssessmentQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalityAssessmentQuery(baseOptions?: Apollo.QueryHookOptions<PersonalityAssessmentQuery, PersonalityAssessmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalityAssessmentQuery, PersonalityAssessmentQueryVariables>(PersonalityAssessmentDocument, options);
      }
export function usePersonalityAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalityAssessmentQuery, PersonalityAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalityAssessmentQuery, PersonalityAssessmentQueryVariables>(PersonalityAssessmentDocument, options);
        }
export type PersonalityAssessmentQueryHookResult = ReturnType<typeof usePersonalityAssessmentQuery>;
export type PersonalityAssessmentLazyQueryHookResult = ReturnType<typeof usePersonalityAssessmentLazyQuery>;
export type PersonalityAssessmentQueryResult = Apollo.QueryResult<PersonalityAssessmentQuery, PersonalityAssessmentQueryVariables>;
export const PersonalityAssessmentSaveAnswersDocument = gql`
    mutation PersonalityAssessmentSaveAnswers($input: PersonalityAssessmentAnswersInput!) {
  personalityAssessmentSaveAnswers(input: $input) {
    id
  }
}
    `;
export type PersonalityAssessmentSaveAnswersMutationFn = Apollo.MutationFunction<PersonalityAssessmentSaveAnswersMutation, PersonalityAssessmentSaveAnswersMutationVariables>;

/**
 * __usePersonalityAssessmentSaveAnswersMutation__
 *
 * To run a mutation, you first call `usePersonalityAssessmentSaveAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalityAssessmentSaveAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalityAssessmentSaveAnswersMutation, { data, loading, error }] = usePersonalityAssessmentSaveAnswersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonalityAssessmentSaveAnswersMutation(baseOptions?: Apollo.MutationHookOptions<PersonalityAssessmentSaveAnswersMutation, PersonalityAssessmentSaveAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PersonalityAssessmentSaveAnswersMutation, PersonalityAssessmentSaveAnswersMutationVariables>(PersonalityAssessmentSaveAnswersDocument, options);
      }
export type PersonalityAssessmentSaveAnswersMutationHookResult = ReturnType<typeof usePersonalityAssessmentSaveAnswersMutation>;
export type PersonalityAssessmentSaveAnswersMutationResult = Apollo.MutationResult<PersonalityAssessmentSaveAnswersMutation>;
export type PersonalityAssessmentSaveAnswersMutationOptions = Apollo.BaseMutationOptions<PersonalityAssessmentSaveAnswersMutation, PersonalityAssessmentSaveAnswersMutationVariables>;
export const PersonalityAssessmentInviteMembersDocument = gql`
    mutation PersonalityAssessmentInviteMembers($input: PersonalityAssessmentInviteMembersInput!) {
  personalityAssessmentInviteMembers(input: $input) {
    error
  }
}
    `;
export type PersonalityAssessmentInviteMembersMutationFn = Apollo.MutationFunction<PersonalityAssessmentInviteMembersMutation, PersonalityAssessmentInviteMembersMutationVariables>;

/**
 * __usePersonalityAssessmentInviteMembersMutation__
 *
 * To run a mutation, you first call `usePersonalityAssessmentInviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalityAssessmentInviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalityAssessmentInviteMembersMutation, { data, loading, error }] = usePersonalityAssessmentInviteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonalityAssessmentInviteMembersMutation(baseOptions?: Apollo.MutationHookOptions<PersonalityAssessmentInviteMembersMutation, PersonalityAssessmentInviteMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PersonalityAssessmentInviteMembersMutation, PersonalityAssessmentInviteMembersMutationVariables>(PersonalityAssessmentInviteMembersDocument, options);
      }
export type PersonalityAssessmentInviteMembersMutationHookResult = ReturnType<typeof usePersonalityAssessmentInviteMembersMutation>;
export type PersonalityAssessmentInviteMembersMutationResult = Apollo.MutationResult<PersonalityAssessmentInviteMembersMutation>;
export type PersonalityAssessmentInviteMembersMutationOptions = Apollo.BaseMutationOptions<PersonalityAssessmentInviteMembersMutation, PersonalityAssessmentInviteMembersMutationVariables>;
export const GetPersonalityResultsQueriesDocument = gql`
    query getPersonalityResultsQueries($filters: [PersonalityResultsQueryInput]) {
  personalityResults(filters: $filters) {
    personalityType
    name
    firstName
    lastName
    completedDate
    division
    jobTitle
  }
}
    `;

/**
 * __useGetPersonalityResultsQueriesQuery__
 *
 * To run a query within a React component, call `useGetPersonalityResultsQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalityResultsQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalityResultsQueriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPersonalityResultsQueriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPersonalityResultsQueriesQuery, GetPersonalityResultsQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalityResultsQueriesQuery, GetPersonalityResultsQueriesQueryVariables>(GetPersonalityResultsQueriesDocument, options);
      }
export function useGetPersonalityResultsQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalityResultsQueriesQuery, GetPersonalityResultsQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalityResultsQueriesQuery, GetPersonalityResultsQueriesQueryVariables>(GetPersonalityResultsQueriesDocument, options);
        }
export type GetPersonalityResultsQueriesQueryHookResult = ReturnType<typeof useGetPersonalityResultsQueriesQuery>;
export type GetPersonalityResultsQueriesLazyQueryHookResult = ReturnType<typeof useGetPersonalityResultsQueriesLazyQuery>;
export type GetPersonalityResultsQueriesQueryResult = Apollo.QueryResult<GetPersonalityResultsQueriesQuery, GetPersonalityResultsQueriesQueryVariables>;
export const ShareCultureReportDocument = gql`
    mutation ShareCultureReport($input: SharePageInput!) {
  shareCultureReport(input: $input) {
    link
  }
}
    `;
export type ShareCultureReportMutationFn = Apollo.MutationFunction<ShareCultureReportMutation, ShareCultureReportMutationVariables>;

/**
 * __useShareCultureReportMutation__
 *
 * To run a mutation, you first call `useShareCultureReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCultureReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCultureReportMutation, { data, loading, error }] = useShareCultureReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareCultureReportMutation(baseOptions?: Apollo.MutationHookOptions<ShareCultureReportMutation, ShareCultureReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareCultureReportMutation, ShareCultureReportMutationVariables>(ShareCultureReportDocument, options);
      }
export type ShareCultureReportMutationHookResult = ReturnType<typeof useShareCultureReportMutation>;
export type ShareCultureReportMutationResult = Apollo.MutationResult<ShareCultureReportMutation>;
export type ShareCultureReportMutationOptions = Apollo.BaseMutationOptions<ShareCultureReportMutation, ShareCultureReportMutationVariables>;
export const ExpireShareLinkDocument = gql`
    mutation ExpireShareLink($sk: String!) {
  expireShareLink(sk: $sk)
}
    `;
export type ExpireShareLinkMutationFn = Apollo.MutationFunction<ExpireShareLinkMutation, ExpireShareLinkMutationVariables>;

/**
 * __useExpireShareLinkMutation__
 *
 * To run a mutation, you first call `useExpireShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpireShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expireShareLinkMutation, { data, loading, error }] = useExpireShareLinkMutation({
 *   variables: {
 *      sk: // value for 'sk'
 *   },
 * });
 */
export function useExpireShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<ExpireShareLinkMutation, ExpireShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExpireShareLinkMutation, ExpireShareLinkMutationVariables>(ExpireShareLinkDocument, options);
      }
export type ExpireShareLinkMutationHookResult = ReturnType<typeof useExpireShareLinkMutation>;
export type ExpireShareLinkMutationResult = Apollo.MutationResult<ExpireShareLinkMutation>;
export type ExpireShareLinkMutationOptions = Apollo.BaseMutationOptions<ExpireShareLinkMutation, ExpireShareLinkMutationVariables>;
export const SharePageLinksDocument = gql`
    query SharePageLinks {
  sharePageLinks {
    sk
    dateShared
    link
    title
    expiryDate
    sharer
    sharerEmail
    shareContent
    type
  }
}
    `;

/**
 * __useSharePageLinksQuery__
 *
 * To run a query within a React component, call `useSharePageLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePageLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePageLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharePageLinksQuery(baseOptions?: Apollo.QueryHookOptions<SharePageLinksQuery, SharePageLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharePageLinksQuery, SharePageLinksQueryVariables>(SharePageLinksDocument, options);
      }
export function useSharePageLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharePageLinksQuery, SharePageLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharePageLinksQuery, SharePageLinksQueryVariables>(SharePageLinksDocument, options);
        }
export type SharePageLinksQueryHookResult = ReturnType<typeof useSharePageLinksQuery>;
export type SharePageLinksLazyQueryHookResult = ReturnType<typeof useSharePageLinksLazyQuery>;
export type SharePageLinksQueryResult = Apollo.QueryResult<SharePageLinksQuery, SharePageLinksQueryVariables>;
export const GetTagActivitiesWithoutHistoryDocument = gql`
    query getTagActivitiesWithoutHistory($filter: TagActivityFilter!) {
  tagActivities(filter: $filter) {
    tag {
      __typename
      ... on SystemTag {
        id
      }
      ... on OrganisationTag {
        id
        label
      }
    }
    actionedByUser {
      user {
        id
        firstName
        lastName
      }
      timestamp
    }
    action
    targetApplicant {
      candidateId
    }
  }
}
    `;

/**
 * __useGetTagActivitiesWithoutHistoryQuery__
 *
 * To run a query within a React component, call `useGetTagActivitiesWithoutHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagActivitiesWithoutHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagActivitiesWithoutHistoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTagActivitiesWithoutHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetTagActivitiesWithoutHistoryQuery, GetTagActivitiesWithoutHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagActivitiesWithoutHistoryQuery, GetTagActivitiesWithoutHistoryQueryVariables>(GetTagActivitiesWithoutHistoryDocument, options);
      }
export function useGetTagActivitiesWithoutHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagActivitiesWithoutHistoryQuery, GetTagActivitiesWithoutHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagActivitiesWithoutHistoryQuery, GetTagActivitiesWithoutHistoryQueryVariables>(GetTagActivitiesWithoutHistoryDocument, options);
        }
export type GetTagActivitiesWithoutHistoryQueryHookResult = ReturnType<typeof useGetTagActivitiesWithoutHistoryQuery>;
export type GetTagActivitiesWithoutHistoryLazyQueryHookResult = ReturnType<typeof useGetTagActivitiesWithoutHistoryLazyQuery>;
export type GetTagActivitiesWithoutHistoryQueryResult = Apollo.QueryResult<GetTagActivitiesWithoutHistoryQuery, GetTagActivitiesWithoutHistoryQueryVariables>;
export const GetOrganisationTagActivitiesDocument = gql`
    query getOrganisationTagActivities($filter: TagActivityFilter!) {
  tagActivities(filter: $filter) {
    tag {
      __typename
      ... on OrganisationTag {
        id
        label
      }
    }
    actionedByUser {
      user {
        id
        firstName
        lastName
      }
      timestamp
    }
    notes
    action
    targetApplicant {
      candidateId
    }
  }
}
    `;

/**
 * __useGetOrganisationTagActivitiesQuery__
 *
 * To run a query within a React component, call `useGetOrganisationTagActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationTagActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationTagActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrganisationTagActivitiesQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationTagActivitiesQuery, GetOrganisationTagActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationTagActivitiesQuery, GetOrganisationTagActivitiesQueryVariables>(GetOrganisationTagActivitiesDocument, options);
      }
export function useGetOrganisationTagActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationTagActivitiesQuery, GetOrganisationTagActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationTagActivitiesQuery, GetOrganisationTagActivitiesQueryVariables>(GetOrganisationTagActivitiesDocument, options);
        }
export type GetOrganisationTagActivitiesQueryHookResult = ReturnType<typeof useGetOrganisationTagActivitiesQuery>;
export type GetOrganisationTagActivitiesLazyQueryHookResult = ReturnType<typeof useGetOrganisationTagActivitiesLazyQuery>;
export type GetOrganisationTagActivitiesQueryResult = Apollo.QueryResult<GetOrganisationTagActivitiesQuery, GetOrganisationTagActivitiesQueryVariables>;
export const GetTagActivitiesDocument = gql`
    query getTagActivities($filter: TagActivityFilter!) {
  tagActivities(filter: $filter) {
    tag {
      __typename
      ... on SystemTag {
        id
      }
      ... on OrganisationTag {
        id
        label
        assignedCount
      }
    }
    actionedByUser {
      user {
        id
        firstName
        lastName
      }
      timestamp
    }
    action
    notes
    targetApplicant {
      candidateId
    }
    history {
      action
      actionedByUser {
        user {
          id
          firstName
          lastName
        }
        timestamp
      }
      notes
    }
  }
}
    `;

/**
 * __useGetTagActivitiesQuery__
 *
 * To run a query within a React component, call `useGetTagActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTagActivitiesQuery(baseOptions: Apollo.QueryHookOptions<GetTagActivitiesQuery, GetTagActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagActivitiesQuery, GetTagActivitiesQueryVariables>(GetTagActivitiesDocument, options);
      }
export function useGetTagActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagActivitiesQuery, GetTagActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagActivitiesQuery, GetTagActivitiesQueryVariables>(GetTagActivitiesDocument, options);
        }
export type GetTagActivitiesQueryHookResult = ReturnType<typeof useGetTagActivitiesQuery>;
export type GetTagActivitiesLazyQueryHookResult = ReturnType<typeof useGetTagActivitiesLazyQuery>;
export type GetTagActivitiesQueryResult = Apollo.QueryResult<GetTagActivitiesQuery, GetTagActivitiesQueryVariables>;
export const OrganisationTagsDocument = gql`
    query organisationTags {
  organisationTags {
    id
    label
    tagCategory
    assignedCount
  }
}
    `;

/**
 * __useOrganisationTagsQuery__
 *
 * To run a query within a React component, call `useOrganisationTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationTagsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationTagsQuery, OrganisationTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationTagsQuery, OrganisationTagsQueryVariables>(OrganisationTagsDocument, options);
      }
export function useOrganisationTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationTagsQuery, OrganisationTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationTagsQuery, OrganisationTagsQueryVariables>(OrganisationTagsDocument, options);
        }
export type OrganisationTagsQueryHookResult = ReturnType<typeof useOrganisationTagsQuery>;
export type OrganisationTagsLazyQueryHookResult = ReturnType<typeof useOrganisationTagsLazyQuery>;
export type OrganisationTagsQueryResult = Apollo.QueryResult<OrganisationTagsQuery, OrganisationTagsQueryVariables>;
export const SystemTagActivitySetDocument = gql`
    mutation systemTagActivitySet($input: SystemTagActivityInput!) {
  systemTagActivitySet(input: $input) {
    tagActivity {
      tag {
        ... on SystemTag {
          id
          label
        }
      }
      targetApplicant {
        listingId
        candidateId
      }
      action
      actionedByUser {
        user {
          id
        }
      }
    }
    errors {
      message
    }
  }
}
    `;
export type SystemTagActivitySetMutationFn = Apollo.MutationFunction<SystemTagActivitySetMutation, SystemTagActivitySetMutationVariables>;

/**
 * __useSystemTagActivitySetMutation__
 *
 * To run a mutation, you first call `useSystemTagActivitySetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSystemTagActivitySetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [systemTagActivitySetMutation, { data, loading, error }] = useSystemTagActivitySetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSystemTagActivitySetMutation(baseOptions?: Apollo.MutationHookOptions<SystemTagActivitySetMutation, SystemTagActivitySetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SystemTagActivitySetMutation, SystemTagActivitySetMutationVariables>(SystemTagActivitySetDocument, options);
      }
export type SystemTagActivitySetMutationHookResult = ReturnType<typeof useSystemTagActivitySetMutation>;
export type SystemTagActivitySetMutationResult = Apollo.MutationResult<SystemTagActivitySetMutation>;
export type SystemTagActivitySetMutationOptions = Apollo.BaseMutationOptions<SystemTagActivitySetMutation, SystemTagActivitySetMutationVariables>;
export const OrganisationTagCreateDocument = gql`
    mutation OrganisationTagCreate($input: OrganisationTagCreateInput!) {
  organisationTagCreate(input: $input) {
    tag {
      __typename
      ... on OrganisationTag {
        id
        label
      }
    }
    errors {
      message
      code
    }
  }
}
    `;
export type OrganisationTagCreateMutationFn = Apollo.MutationFunction<OrganisationTagCreateMutation, OrganisationTagCreateMutationVariables>;

/**
 * __useOrganisationTagCreateMutation__
 *
 * To run a mutation, you first call `useOrganisationTagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationTagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationTagCreateMutation, { data, loading, error }] = useOrganisationTagCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationTagCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationTagCreateMutation, OrganisationTagCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationTagCreateMutation, OrganisationTagCreateMutationVariables>(OrganisationTagCreateDocument, options);
      }
export type OrganisationTagCreateMutationHookResult = ReturnType<typeof useOrganisationTagCreateMutation>;
export type OrganisationTagCreateMutationResult = Apollo.MutationResult<OrganisationTagCreateMutation>;
export type OrganisationTagCreateMutationOptions = Apollo.BaseMutationOptions<OrganisationTagCreateMutation, OrganisationTagCreateMutationVariables>;
export const OrganisationTagActivitySetDocument = gql`
    mutation OrganisationTagActivitySet($input: OrganisationTagActivityInput!) {
  organisationTagActivitySet(input: $input) {
    tagActivities {
      tag {
        ... on SystemTag {
          id
          label
        }
        ... on OrganisationTag {
          id
          label
        }
      }
      targetApplicant {
        listingId
        candidateId
      }
      notes
      action
      actionedByUser {
        user {
          id
        }
        timestamp
      }
    }
    errors {
      message
    }
  }
}
    `;
export type OrganisationTagActivitySetMutationFn = Apollo.MutationFunction<OrganisationTagActivitySetMutation, OrganisationTagActivitySetMutationVariables>;

/**
 * __useOrganisationTagActivitySetMutation__
 *
 * To run a mutation, you first call `useOrganisationTagActivitySetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationTagActivitySetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationTagActivitySetMutation, { data, loading, error }] = useOrganisationTagActivitySetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationTagActivitySetMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationTagActivitySetMutation, OrganisationTagActivitySetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationTagActivitySetMutation, OrganisationTagActivitySetMutationVariables>(OrganisationTagActivitySetDocument, options);
      }
export type OrganisationTagActivitySetMutationHookResult = ReturnType<typeof useOrganisationTagActivitySetMutation>;
export type OrganisationTagActivitySetMutationResult = Apollo.MutationResult<OrganisationTagActivitySetMutation>;
export type OrganisationTagActivitySetMutationOptions = Apollo.BaseMutationOptions<OrganisationTagActivitySetMutation, OrganisationTagActivitySetMutationVariables>;
export const DeleteOrganisationTagDocument = gql`
    mutation DeleteOrganisationTag($input: DeleteOrganisationTagInput!) {
  deleteOrganisationTag(input: $input) {
    tag {
      __typename
      ... on OrganisationTag {
        id
        label
      }
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteOrganisationTagMutationFn = Apollo.MutationFunction<DeleteOrganisationTagMutation, DeleteOrganisationTagMutationVariables>;

/**
 * __useDeleteOrganisationTagMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationTagMutation, { data, loading, error }] = useDeleteOrganisationTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganisationTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganisationTagMutation, DeleteOrganisationTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganisationTagMutation, DeleteOrganisationTagMutationVariables>(DeleteOrganisationTagDocument, options);
      }
export type DeleteOrganisationTagMutationHookResult = ReturnType<typeof useDeleteOrganisationTagMutation>;
export type DeleteOrganisationTagMutationResult = Apollo.MutationResult<DeleteOrganisationTagMutation>;
export type DeleteOrganisationTagMutationOptions = Apollo.BaseMutationOptions<DeleteOrganisationTagMutation, DeleteOrganisationTagMutationVariables>;