import { MainNav, MainNavButton, MainNavLink } from "@compono/ui";
import * as intercom from "@shortlyster/intercom-kit";

import { ShowIf } from "~/components/auth/ShowIf";
import { useAuthentication } from "~/hooks/useAuthentication";

import { SignInOutlineIcon } from "../icons/SignInOutlineIcon";
import { MembershipOutlineIcon } from "../icons/MembershipOutlineIcon";
import { NavLink } from "../NavLink";

import { AdminNav } from "./AdminNav";
import { EngageNav } from "./EngageNav";
import { DevelopNav } from "./DevelopNav";
import { HireNav } from "./HireNav";
import { DeniedNav } from "./DeniedNav";
import { ReportsNav } from "./ReportsNav";

export const SideNav = () => {
  const { logout } = useAuthentication();

  const signOut = () => {
    if (
      document.body.getAttribute("data-modified") &&
      !window.confirm(
        "You have unsaved changes. Changes will be lost if you leave this page."
      )
    )
      return;

    window.sessionStorage.clear();
    intercom.shutdown();

    logout({ returnTo: window.location.origin });
  };

  return (
    <MainNav navLinkComponent={NavLink}>
      <ShowIf allowedTo="product.hire">
        <HireNav />
      </ShowIf>
      <ShowIf allowedTo="product.engage">
        <EngageNav />
      </ShowIf>
      <ShowIf allowedTo="product.develop">
        <DevelopNav />
      </ShowIf>
      <AdminNav />

      <ShowIf allowedTo="hireReports.view">
        <ReportsNav />
      </ShowIf>

      <DeniedNav />
      <MainNavLink
        icon={MembershipOutlineIcon}
        href={`/profile`}
        label="My profile"
      />
      <MainNavButton
        icon={SignInOutlineIcon}
        onClick={signOut}
        data-testid="logout-link"
        label="Log out"
      />
    </MainNav>
  );
};
